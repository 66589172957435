import React from 'react'
 function Inventory() {
  return (
    <div>
       Aaao na
    </div>
  )
}


export default Inventory