import React,{useState} from 'react'
import styled from 'styled-components'
import MenuIcon  from '@material-ui/icons/Menu';
import CloseIcon  from '@material-ui/icons/Close';



function Header() {

 const [burgerStatus, setBurgerStatus] = useState(false);
 
 return (
    <Container>
      {/* <a>
        <img src= "/images/logo.svg" alt=""/>
      </a> */}
      <Menu>
      <a href="#">Model S</a>
      <a href="#">Model 3</a>
      <a href="#">Model X</a>
      <a href="#">Model Y</a> 
      </Menu>

      <RightMenu>
      <a href="#">Shop</a>
      <a href="#">Fesla Account</a>
      <CustomeMenu onClick={() => setBurgerStatus(true)} />
      </RightMenu>

      <BurgerNav show={burgerStatus}>
        <CloseWrapper>
        <CustomeClose onClick={() => setBurgerStatus(false)} />
        </CloseWrapper> 
        <li><a href="#">Existing Inventory</a></li>
        <li><a href="#">Used Inventory</a></li>
        <li><a href="#">Trade-in Inventory</a></li>
        <li><a href="#">CyberTruck </a></li>
        <li><a href="#">Roadester</a></li>
        <li><a href="#">Existing Inventory</a></li>
        <li><a href="#">Existing Inventory</a></li>
      
      </BurgerNav>
    </Container>
  )
}

export default Header

const Container = styled.div`
z-index:1;   
min-height  : 60px;
  position: fixed;
  display: flex;
  padding: 0 20px;
  align-items : center;
  justify-content : space-between; 
  top: 0;
  left: 0;
  right : 0;
`


const Menu = styled.div`
  display: flex;    
  align-items : center;
  justify-content: center;
  flex: 1;
   a{
    font-weight: 600;
    text-transform : uppercase;
    padding : 0 10px;
    flex-wrap : nowrap;
   }
   @media (max-width : 768px)
   {
       display:none;
   }
   `


const RightMenu = styled.div`
display:flex;
align-items:center;

a{
    font-weight: 600;
    text-transform : uppercase;
    margin-right : 10px;
    flex-wrap : nowrap;
   }


`

const CustomeMenu = styled(MenuIcon)`
  cursor : pointer; 
`
const CustomeClose = styled(CloseIcon)`
  cursor : pointer; 
`
const CloseWrapper = styled.div`
  display : flex;
  justify-content : flex-end; 
`

const BurgerNav  = styled.div`
  position : fixed;
  top : 0;
  Bottom : 0 ;
Right: 0;
Background: white; 
width: 300px;
z-index: 16;
list-style: none;
padding : 20px;
display:flex;
flex-direction: column;
text-align : start;
justify-content: flex-start;
transform : ${props => props.show ?'translateX(0)' : 'translateX(100%)' }; 
transition: transform 0.2s ; 
li{
    padding : 15px 0;
    border-bottom : 1px solid rgba(0 , 0, 0 , .2);

    a{
        font-weight: 600;
    }
 }


`