import React from 'react'
import styled  from 'styled-components'
import Fade from 'react-reveal/Fade'
import Inventory from './Inventory'

const Order = () => {
  console.log("Hello 1");
    <Inventory />
    console.log("Hello 2")
}

function Section(props) {
    console.log("prosps ",props)
  return (
    <Wrap bgImage =  {props.backgroundImg}>
     <Fade bottom>
     <ItemText>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
     </ItemText>
     </Fade>
     <Buttons>
     <Fade bottom>
     <ButtonGroup>
        <LeftButton>
          {props.leftBtnText}
        </LeftButton>
       {props.rightBtnText && 
        <RightButton onClick = {() => Order()}>
        {props.rightBtnText}
        </RightButton>
       } 
     </ButtonGroup>
     </Fade>
     <DownArrow src="/images/down-arrow.svg"/>
     </Buttons>
    </Wrap>
  )
}

export default Section

const Wrap = styled.div`
z-index: 10;
width:100vw;
height:100vh;
background-position: center;
background-size:cover; 
background-repeat: no-repeat;
background-image : url('/images/model-s.jpg');
display : flex;
flex-direction: column;
justify-content : space-between;
align-items : center;
background-image : ${props => `url("/images/${props.bgImage}")`}
`

const ItemText= styled.div`
padding-top : 15vh ;
z-index:-1;
text-align : center ;
`

const ButtonGroup= styled.div`
  display: flex;
  margin-bottom: 30px;
  @media (max-width: 768px)
  {
     flex-direction:column;
  }
`

const LeftButton= styled.div`
  height: 40px;
  width: 256px;
  color: white;
  display: flex;
  background-color : rgba(23,26,32,0.8);
  justify-content: center;
  align-items : center;
  border-radius : 100px;
  opacity: 0.85;
  text-transform : uppercase;
  font-size: 12px;
  cursor: pointer; 
  margin: 8px;
  `

const RightButton= styled(LeftButton) `
  background : white;
  opacity : 0.65;
  color : black;
`


const DownArrow= styled.img  `
 height: 40px;
 overflow-x: hidden; 
 animation : animateDown infinite 1.5s;
`


const Buttons= styled.div `

`